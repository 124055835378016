// src/views/ThankYou.js
import React from 'react';

const ThankYou = () => {
  return (
    <div>
      <h2>Спасибо, с вами скоро свяжется наш менеджер!</h2>
    </div>
  );
};

export default ThankYou;
