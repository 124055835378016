import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';

import './style.css';
import Home from './views/home';
import NotFound from './views/not-found';
import ThankYou from './views/thank-you';  // Импорт компонента "Спасибо"

const App = () => {
  return (
    <Router>
      <Switch>
        <Route component={Home} exact path="/" />
        <Route component={ThankYou} exact path="/thank-you" />  {/* Новый маршрут */}
        <Route component={NotFound} path="**" />
        <Redirect to="**" />
      </Switch>
    </Router>
  );
};

ReactDOM.render(<App />, document.getElementById('app'));
