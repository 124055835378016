import React from 'react'

import Script from 'dangerous-html/react'
import { Helmet } from 'react-helmet'

import './home.css'
import SignupForm from '../components/SignupForm'; 

const Home = (props) => {
  return (
    <div className="home-container1">
      <Helmet>
        <title>Онлайн-школа Repita</title>
        <meta property="og:title" content="Онлайн-школа Repita" />
      </Helmet>
      <header className="home-header10">
        <header data-thq="thq-navbar" className="home-navbar">
          <span className="home-logo1">Repita</span>
          <div
            data-thq="thq-navbar-nav"
            data-role="Nav"
            className="home-desktop-menu"
          >
            <nav
              data-thq="thq-navbar-nav-links"
              data-role="Nav"
              className="home-nav1"
            >
              <a href="#home-container4" className="home-text10">
                  О нас
                </a>
                <a href="#otzivi1" className="home-text11">
                  Отзывы
                </a>
                <a href="#metodika" className="home-text12">
                  Методика
                </a>
                <a href="#programm_cource" className="home-text13">
                  Программа
                </a>
                <a href="#FAQ_voprosi" className="home-text14">
                  FAQ
                </a>
            </nav>
          </div>
          <div data-thq="thq-navbar-btn-group" className="home-btn-group">
          <a href="#to_form" className="button">Записаться на урок</a>
            
          </div>
          <div data-thq="thq-burger-menu" className="home-burger-menu">
            <button className="button home-button6">
              <svg viewBox="0 0 1024 1024" className="home-icon10">
                <path d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
              </svg>
            </button>
          </div>
          <div data-thq="thq-mobile-menu" className="home-mobile-menu">
            <div
              data-thq="thq-mobile-menu-nav"
              data-role="Nav"
              className="home-nav2"
            >
              <div className="home-container2">
                <span className="home-logo2">Repita</span>
                <div data-thq="thq-close-menu" className="home-menu-close">
                  <svg viewBox="0 0 1024 1024" className="home-icon12">
                    <path d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"></path>
                  </svg>
                </div>
              </div>
              <nav
                data-thq="thq-mobile-menu-nav-links"
                data-role="Nav"
                className="home-nav3"
              >
                {/* <span className="home-text10">О нас</span>
                <span className="home-text11">Features</span>
                <span className="home-text12">Pricing</span>
                <span className="home-text13">Team</span>
                <span className="home-text14">Blog</span> */}
                <a href="#home-container4" className="home-text10">
                  О нас
                </a>
                <a href="#otzivi1" className="home-text11">
                  Отзывы
                </a>
                <a href="#metodika" className="home-text12">
                  Методика
                </a>
                <a href="#programm_cource" className="home-text13">
                  Программа
                </a>
                <a href="#FAQ_voprosi" className="home-text14">
                  FAQ
                </a>
                
                </nav>
              
              <a href="#to_form" className="home-login button">Задать вопрос</a>
            </div>
            {/* <div className="home-icon-group">
              <svg viewBox="0 0 950.8571428571428 1024" className="home-icon14">
                <path d="M925.714 233.143c-25.143 36.571-56.571 69.143-92.571 95.429 0.571 8 0.571 16 0.571 24 0 244-185.714 525.143-525.143 525.143-104.571 0-201.714-30.286-283.429-82.857 14.857 1.714 29.143 2.286 44.571 2.286 86.286 0 165.714-29.143 229.143-78.857-81.143-1.714-149.143-54.857-172.571-128 11.429 1.714 22.857 2.857 34.857 2.857 16.571 0 33.143-2.286 48.571-6.286-84.571-17.143-148-91.429-148-181.143v-2.286c24.571 13.714 53.143 22.286 83.429 23.429-49.714-33.143-82.286-89.714-82.286-153.714 0-34.286 9.143-65.714 25.143-93.143 90.857 112 227.429 185.143 380.571 193.143-2.857-13.714-4.571-28-4.571-42.286 0-101.714 82.286-184.571 184.571-184.571 53.143 0 101.143 22.286 134.857 58.286 41.714-8 81.714-23.429 117.143-44.571-13.714 42.857-42.857 78.857-81.143 101.714 37.143-4 73.143-14.286 106.286-28.571z"></path>
              </svg>
              <svg viewBox="0 0 877.7142857142857 1024" className="home-icon16">
                <path d="M585.143 512c0-80.571-65.714-146.286-146.286-146.286s-146.286 65.714-146.286 146.286 65.714 146.286 146.286 146.286 146.286-65.714 146.286-146.286zM664 512c0 124.571-100.571 225.143-225.143 225.143s-225.143-100.571-225.143-225.143 100.571-225.143 225.143-225.143 225.143 100.571 225.143 225.143zM725.714 277.714c0 29.143-23.429 52.571-52.571 52.571s-52.571-23.429-52.571-52.571 23.429-52.571 52.571-52.571 52.571 23.429 52.571 52.571zM438.857 152c-64 0-201.143-5.143-258.857 17.714-20 8-34.857 17.714-50.286 33.143s-25.143 30.286-33.143 50.286c-22.857 57.714-17.714 194.857-17.714 258.857s-5.143 201.143 17.714 258.857c8 20 17.714 34.857 33.143 50.286s30.286 25.143 50.286 33.143c57.714 22.857 194.857 17.714 258.857 17.714s201.143 5.143 258.857-17.714c20-8 34.857-17.714 50.286-33.143s25.143-30.286 33.143-50.286c22.857-57.714 17.714-194.857 17.714-258.857s5.143-201.143-17.714-258.857c-8-20-17.714-34.857-33.143-50.286s-30.286-25.143-50.286-33.143c-57.714-22.857-194.857-17.714-258.857-17.714zM877.714 512c0 60.571 0.571 120.571-2.857 181.143-3.429 70.286-19.429 132.571-70.857 184s-113.714 67.429-184 70.857c-60.571 3.429-120.571 2.857-181.143 2.857s-120.571 0.571-181.143-2.857c-70.286-3.429-132.571-19.429-184-70.857s-67.429-113.714-70.857-184c-3.429-60.571-2.857-120.571-2.857-181.143s-0.571-120.571 2.857-181.143c3.429-70.286 19.429-132.571 70.857-184s113.714-67.429 184-70.857c60.571-3.429 120.571-2.857 181.143-2.857s120.571-0.571 181.143 2.857c70.286 3.429 132.571 19.429 184 70.857s67.429 113.714 70.857 184c3.429 60.571 2.857 120.571 2.857 181.143z"></path>
              </svg>
              <svg viewBox="0 0 602.2582857142856 1024" className="home-icon18">
                <path d="M548 6.857v150.857h-89.714c-70.286 0-83.429 33.714-83.429 82.286v108h167.429l-22.286 169.143h-145.143v433.714h-174.857v-433.714h-145.714v-169.143h145.714v-124.571c0-144.571 88.571-223.429 217.714-223.429 61.714 0 114.857 4.571 130.286 6.857z"></path>
              </svg>
            </div> */}
          </div>
        </header>
      </header>
      <section className="home-hero">
        <div className="home-heading10">
          <h1 className="home-header11">Подготовься к успешной сдаче ЕГЭ по информатике!</h1>
          <p className="home-caption10">
            👉 "На 85+ баллов с нуля — это реально!"
            Ваши высокие результаты — наша цель.
          </p>
          <p className="home-caption10">
            ❗️Только эффективные методики и индивидуальный подход.
            Начните подготовку уже СЕГОДНЯ!
          </p>
        </div>
        <div className="home-buttons">
          <a href="#to_form" className="button">Записаться на урок</a>
          
        </div>
      </section>
      <section className="home-description10">
        <img
          alt="image"
          src="/hero-divider-1500w.png"
          className="home-divider-image"
        />
        <div id="home-container4" className="home-container4">
          <div className="home-description11">
            <div className="home-content10">
              <p className="home-paragraph1">
                Мы — профессионалы своего дела и знаем как сделать процесс обучения для каждого ученика комфортным❤️
              </p>
              <p className="home-paragraph2">
                Поэтому, мы предлагаем:
              </p>
              <p className="home-paragraph2">
                ✔️ Гибкий график обучения — вы сможете заниматься в любое удобное для вас время, а мы его, с удовольствием, подберем.
              </p>
              <p className="home-paragraph2">
                ✔️ Персонализированные методики обучения — наши преподаватели подберут индивидуальную программу под ваши потребности и уровень знаний.
              </p>
              <p className="home-paragraph2">
                ✔️ Мгновенная обратная связь — мы всегда поддерживаем наших учеников, поэтому вы сможете обратиться к учителю, когда вам понадобится.
              </p>
              <p className="home-paragraph2">
                ✔️ Записи занятий — каждый урок мы записываем, чтобы вы смогли в любое время их пересматривать и повторять сложные темы.
              </p>
              <p className="home-paragraph2">
                ✔️ Только актуальная информация — мы всегда проверяем соответствуют ли наши программы последним изменениям в экзаменационных требованиях.
              </p>
            </div>
            
          </div>
        </div>
      </section>
      <section className="home-cards">
        <div className="home-row1">
          <div className="home-card10">
            <div className="home-avatar1">
              <img
                alt="image"
                src="/Avatars/avatar.svg"
                className="home-avatar2"
              />
            </div>
            <div className="home-main1">
              <div className="home-content11">
                <h2 className="home-header12">100+ учеников каждый год сдают ЕГЭ на 85+ баллов</h2>
                <p className="home-description12">
                  Мы разработали авторскую методику подготовки, которая помогает достичь высоких результатов на ЕГЭ.
                </p>
              </div>
              <button className="home-learn2 button">
                {/* <span className="home-text17">Записаться на урок</span> */}
                <a href="#to_form" className="home-text17">Записаться на урок</a>
                <img
                  alt="image"
                  src="/Icons/arrow.svg"
                  className="home-image12"
                />
              </button>
            </div>
          </div>
          <div className="home-card11">
            <div className="home-avatar3">
              <img
                alt="image"
                src="/Avatars/default-avatar.svg"
                className="home-avatar4"
              />
            </div>
            <div className="home-main2">
              <div className="home-content12">
                <h2 className="home-header13">
                  Начни свою подготовку прямо сейчас
                </h2>
                <p className="home-description13">
                  Наша цель — обеспечить каждого ученика полным комплектом знаний для успешной сдачи ЕГЭ по информатике.
                </p>
              </div>
              <button className="home-learn3 button">
                <a href="#to_form" className="home-text17">Записаться на урок</a>
                <img
                  alt="image"
                  src="/Icons/arrow-2.svg"
                  className="home-image13"
                />
              </button>
            </div>
          </div>
        </div>
        <div className="home-card12">
          <div className="home-avatar5">
            <img
              alt="image"
              src="/Avatars/light-avatar.svg"
              className="home-avatar6"
            />
          </div>
          <div className="home-row2">
            <div className="home-main3">
              <div className="home-content13">
                <h2 className="home-header14">
                  Подготовь свое будущее сегодня
                </h2>
                <p className="home-description14">
                  Каждый урок нашей программы направлен на устранение пробелов в знаниях и подготовку к высоким результатам на ЕГЭ.
                </p>
              </div>
              
              <a href="#to_form" className="home-learn4 button">Задать вопрос</a>
            </div>
            {/* <img alt="image" src="/group%202262.svg" className="home-image15" /> */}
          </div>
        </div>
      </section>
      <section className="home-collection">
        <div id="otzivi1"  className="home-content14">
          <span className="home-caption11">Отзывы</span>
          <div className="home-heading11">
            <h2 className="home-header15">Наши выпускники говорят о нас</h2>
            <p className="home-header16">
              Этапы подготовки включают анализ пробелов, изучение теории, выполнение практических задач и работу над тестами.
            </p>
            
          </div>
        </div>
        <div  className="home-main4">
        <div className="home-card16">
            <div className="home-image22">
              <img
                alt="image"
                src="/Characters/Иван.jpg"
                className="home-image23"
              />
            </div>
            <div className="home-content18">
              <span className="home-caption15">
                <span>Иван</span>
                <br></br>
                <span className="home-caption14">Результат 98 баллов</span>
              </span>
              <h3 className="home-title4"> В школе было трудно понять как писать код, учителя не могли понятно и интересно донести материал (хотя очень хотелось понимать).На первом занятии уже начал понимать синтаксис языка python, потом уже начал понимать как работают типы данных и так далее :). Также хочу отметить, что мне было не скучно готовиться к экзамену, я чувствовал себя на одной волне с преподавателем. Наши занятия были очень веселые и в то же время продуктивные. Именно благодаря ему я сдал ЕГЭ по информатике на 98 баллов и поступил на направление с высоким проходным баллом, чему я до сих пор очень благодарен.</h3>
            </div>
          </div>
          <div className="home-card14">
            <div className="home-image18">
              <img
                alt="image"
                src="/Characters/Никита.jpg"
                className="home-image19"
              />
            </div>
            <div className="home-content16">
              <span className="home-caption13">Никита</span>
              <span className="home-caption13">Результат 80 баллов</span>
              <h3 className="home-title2">Замечательный преподаватель! Материал подает в доступной и понятной манере, занятия проходят интересно. В случае, если что-то было не понято, без помощи не оставят!</h3>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
            </div>
          </div>
          <div className="home-card15">
            <div className="home-image20">
              <img
                alt="image"
                src="/Characters/Тимур.jpg"
                className="home-image21"
              />
            </div>
            <div className="home-content17">
              <span className="home-caption14">Тимур</span>
              <span className="home-caption14">Результат 88 баллов</span>
              <h3 className="home-title3">Хочу выразить огромную благодарность Радмиру. Практически с нуля он помог мне подготовиться и сдать экзамен на достойный балл. Занятия были очень интересными, заряжали учиться и самому в свободное время. Было супер круто, однозначно рекомендую! Успехов всем прочитавшим, ботайте прогу с Радмиром!!</h3>
              <br></br>
              <br></br>
              
              
            </div>
          </div>
          <div className="home-card13">
            <div className="home-image16">
              <img
                alt="image"
                src="/Characters/Алина.jpg"
                className="home-image17"
              />
            </div>
            <div className="home-content15">
              <span className="home-caption12">Алина</span>
              <span className="home-caption12">Результат 70 баллов</span>
              <h3 className="home-title1">Подготовку к ЕГЭ мы начали практически в конце мая, т. к. на факультете групповых занятий я поняла, что очень сильно отстаю от ребят. Буквально за несколько занятий смогла хорошо подтянуть материал и сдала на 70 баллов. Упорный и тщательный подход к каждому уроку впечатлил: удалось разобрать сложные темы, которые вызывали трудности.  Рекомендую всем, кто хочет достичь высоких результатов!)</h3>
              
            </div>
          </div>
          
          <div className="home-card17">
            <div className="home-image24">
              <img
                alt="image"
                src="/Characters/Никита2.jpg"
                className="home-image25"
              />
            </div>
            <div className="home-content19">
              <span className="home-caption16">Никита</span>
              <span className="home-caption14">Результат 80 баллов</span>
              <h3 className="home-title5">Начал готовиться к ЕГЭ по информатике в 11 классе в ноябре, до этого никак самостоятельно не готовился, сразу записался на занятия, больше всего понравилось что во время подготовки мне очень помогли развить собственное мышление, всегда дают время подумать немного подталкивая, для меня это был особенный опыт что и посеяло любовь в программировании, несмотря на такие краткие сроки подготовки я сдал информатику на 80 баллов, все благодаря такому подходу.</h3>
            </div>
          </div>
          <div className="home-card18">
            <div className="home-image26">
              <img
                alt="image"
                // src="/Characters/character-6.svg"
                src="/Characters/Максим.jpg"
                className="home-image27"
              />
            </div>
            <div className="home-content20">
              <span className="home-caption17">Максим</span>
              <span className="home-caption14">Результат 93 балла</span>
              <h3 className="home-title6">Начал заниматься в феврале, с ПОЛНОГО нуля, работал много и упорно под чутким руководством учителя. Сдал отлично и поступил туда, куда хотел, большое спасибо! Получил очень хороую БАЗУ, до сих пор помогает в ВУЗЕ. Рекомендую на все 100!</h3>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
            </div>
          </div>
          <div className="home-card19">
            <div className="home-image28">
              <img
                alt="image"
                src="/Characters/Денис.jpg"
                className="home-image29"
              />
            </div>
            <div className="home-content21">
              <span className="home-caption18">Денис</span>
              <span className="home-caption14">Результат 85 баллов</span>
              <h3 className="home-title7">Отличный и молодой преподаватель, старается быть на одной волне с учениками. Всё доходчиво объясняет и помогает во всех вопросах. Благодаря нему можно спокойно сдать экзамен на 70+ баллов готовясь даже год!)</h3>
            </div>
          </div>
          
        </div>
        <a href="#to_form" className="home-view4 button-link button">Записаться на урок</a>
        
        
      </section>
      <section className="home-project">
        <div className="home-understand">
          <div id="metodika" className="home-content23">
            <span className="home-caption20">Методика</span>
            <div className="home-heading12">
              <h2 className="home-header17">Познакомьтесь с нашей методикой подготовки</h2>
              <p className="home-header18">
                Наша методика подготовки основана на многолетнем опыте и современных подходах к обучению. 
                Мы разработали систему, которая учитывает индивидуальные особенности каждого ученика, чтобы максимально эффективно развивать его знания и навыки. Наши программы включают практические задания, интерактивные занятия и пошаговые инструкции, позволяющие быстро и глубоко усвоить материал.
              </p>
            </div>
            <a href="#to_form" className="home-view4 button-link button">Записаться на урок</a>
          </div>
          {/* <img alt="image" src="/group%202415.svg" className="home-image33" /> */}
        </div>
        <div className="home-mining">
          <img alt="image" src="/group%202422.svg" className="home-image34" />
          <div className="home-content24">
            <span className="home-caption21">Подготовка</span>
            <div className="home-heading13">
              <h2 className="home-header19">Как мы готовим к успешной сдаче ЕГЭ по информатике</h2>
              <p className="home-header20">
                <span>
                  Мы создаем персональный план обучения для каждого ученика, ориентируясь на его слабые места и сильные стороны.
                </span>
                <br></br>
                <br></br>
                {/* <span>
                  Duis aute irure dolor in reprehenderit in voluptate velit esse
                  cillum dolore eu fugiat nulla pariatur.
                </span> */}
                <br></br>
              </p>
            </div>
            
            <a href="#to_form" className="home-view5 button-link button">Задать вопрос</a>
          </div>
        </div>
      </section>
      <section className="home-roadmap">
        <div id="programm_cource" className="home-heading14">
          <h2 className="home-header21">Этапы нашей программы подготовки</h2>
          <p className="home-header22">
            Успешная сдача ЕГЭ начинается с первых шагов: оценка знаний, постановка целей и индивидуальный план.
          </p>
        </div>
        <div className="home-list1">
          <div className="home-step1">
            <span className="home-caption22">1 шаг</span>
            <div className="home-heading15">
              <h2 className="home-header23">Первый урок</h2>
              <p className="home-header24">
              👉 На первом занятии опытный преподаватель проводит диагностику знаний для определения уровня подготовки и СРАЗУ начинает подготовку с  учеником. Это помогает нам составить для ученика индивидуальный план обучения. Учитель и ученик работают по нашей методике ABC:
              </p>
              <div className="home-benefits">
                <div className="home-item1">
                  <img
                    alt="image"
                    src="/Icons/people.svg"
                    className="home-image36"
                  />
                  <p className="home-header31">A. Проверка знаний</p>
                </div>
                <div className="home-item2">
                  <img
                    alt="image"
                    src="/Icons/paper.svg"
                    className="home-image37"
                  />
                  <p className="home-header32">B. Теория рука об руку с практикой</p>
                </div>
                <div className="home-item3">
                  <img
                    alt="image"
                    src="/Icons/checklist.svg"
                    className="home-image38"
                  />
                  <p className="home-header33">C. Самостоятельная отработка знаний</p>
                </div>
              </div>
            </div>
            
          </div>
          <div className="home-step2">
            <span className="home-caption23">2 шаг</span>
            <div className="home-heading16">
              <h2 className="home-header25">Упорная работа ученика и преподавателя</h2>
              <p className="home-header26">
                <span>
                  👉 Преподаватель и ученик совместно работают над освоением учебного материала, устраняют пробелы в знаниях и оттачивают навыки решения экзаменационных задач. Используются разнообразные методы обучения: интерактивные уроки, практические задания, обсуждения и обратная связь.
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <br></br>
                <br></br>
                <span>
                  Благодаря только работе, под присмотром и надзором опытного учителя возможно сдать экзамен на высокий балл
                </span>
                <br></br>
              </p>
            </div>
          </div>
          <div className="home-step3">
            <span className="home-caption24">3 шаг</span>
            <div className="home-heading17">
              <h2 className="home-header27">Проверка знаний</h2>
              <p className="home-header28">
                <span>
                👉 Проводятся регулярные тестирования и пробные экзамены, которые имитируют реальные условия сдачи экзамена. Это помогает ученику привыкнуть к формату экзамена и снизить уровень стресса. Анализ результатов позволяет преподавателю выявить области, требующие дополнительного внимания для достижения высоких результатов.
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <br></br>
                <br></br>
                <span>
                  Если ученик что-то забыл, то преподаватель обязательно обратит внимание и поможет вспомнить все, что необходимо
                </span>
                <br></br>
              </p>
            </div>
          </div>
          <div className="home-step4">
            <span className="home-caption25">4 шаг</span>
            <div className="home-heading18">
              <h2 className="home-header29">Сдача экзамена</h2>
              <p className="home-header30">
                👉 Далее, благодаря тщательной подготовке и поддержке преподавателя, ученик уверенно идет на заключительный этап — сдачу ЕГЭ! Наша методика направлена на то, чтобы каждый достиг высоких результатов — 85 баллов и выше.
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </p>
             
            </div>
          </div>
          
          <a href="#to_form" className="home-button7 button">Записаться на урок</a>
        </div>
      </section>
      <section className="home-join-us">
        <div className="home-content25">
          <div className="home-main5">
            <div className="home-heading19">
              <h2 className="home-header34">Начни свою подготовку сегодня</h2>
              <p className="home-caption26">
                Наша программа подготовки уже помогла многим ученикам достичь среднего балла 85+ на ЕГЭ по информатике.
              </p>
            </div>
            
            <a href="#to_form" className="home-view6 button">Записаться на урок</a>
          </div>
          <img alt="image" src="/group%202273.svg" className="home-image39" />
        </div>
      </section>
      <section className="home-faq">
        <h2 id="FAQ_voprosi" className="home-header35">У нас есть ответы на все вопросы по ЕГЭ</h2>
        <div className="home-accordion1">
          <div
            data-role="accordion-container"
            className="home-element1 accordion"
          >
            <div className="home-content26">
              <span className="home-header36">
                Смогу ли я улучшить свой уровень по информатике, если у меня сейчас слабая база?
              </span>
              <span
                data-role="accordion-content"
                className="home-description15"
              >
                  Конечно! Наши преподаватели учитывают ваш текущий уровень знаний и разрабатывают персонализированную программу обучения, чтобы закрыть все пробелы. Даже если у вас слабая база, мы начнем с самых основ и постепенно перейдем к более сложным темам. Уже после первых занятий вы почувствуете уверенность в своих знаниях.
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
            </div>
            <div className="home-icon-container1">
              <svg
                viewBox="0 0 1024 1024"
                data-role="accordion-icon-closed"
                className="home-icon20"
              >
                <path d="M213.333 554.667h256v256c0 23.552 19.115 42.667 42.667 42.667s42.667-19.115 42.667-42.667v-256h256c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-256v-256c0-23.552-19.115-42.667-42.667-42.667s-42.667 19.115-42.667 42.667v256h-256c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
              </svg>
              <svg
                viewBox="0 0 1024 1024"
                data-role="accordion-icon-open"
                className="home-icon22"
              >
                <path d="M213.333 554.667h597.333c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-597.333c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
              </svg>
            </div>
          </div>
          <div
            data-role="accordion-container"
            className="home-element2 accordion"
          >
            <div className="home-content27">
              <span className="home-header37">
                Чем ваша школа отличается от других курсов по подготовке к ЕГЭ по информатике?
              </span>
              <span
                data-role="accordion-content"
                className="home-description16"
              >
                  Мы предлагаем индивидуальный подход к каждому ученику, что помогает эффективно подготовиться к экзамену. Наши преподаватели — эксперты с большим опытом, и мы гарантируем возврат денег, если вам не понравится первый урок. Средний балл наших учеников — 85+, и мы знаем, как помочь вам достичь высоких результатов!
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
            </div>
            <div className="home-icon-container2">
              <svg
                viewBox="0 0 1024 1024"
                data-role="accordion-icon-closed"
                className="home-icon24"
              >
                <path d="M213.333 554.667h256v256c0 23.552 19.115 42.667 42.667 42.667s42.667-19.115 42.667-42.667v-256h256c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-256v-256c0-23.552-19.115-42.667-42.667-42.667s-42.667 19.115-42.667 42.667v256h-256c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
              </svg>
              <svg
                viewBox="0 0 1024 1024"
                data-role="accordion-icon-open"
                className="home-icon26"
              >
                <path d="M213.333 554.667h597.333c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-597.333c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
              </svg>
            </div>
          </div>
          <div
            data-role="accordion-container"
            className="home-element3 accordion"
          >
            <div className="home-content28">
              <span className="home-header38">
                А что, если мне не понравится первый урок?
              </span>
              <span
                data-role="accordion-content"
                className="home-description17"
              >
                  Мы уверены в качестве наших уроков, поэтому предлагаем гарантию возврата денег за первый урок, если он вам не понравится. У вас не будет никаких рисков — только возможность оценить наш подход и уровень преподавания. Попробуйте — и убедитесь сами!
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
            </div>
            <div className="home-icon-container3">
              <svg
                viewBox="0 0 1024 1024"
                data-role="accordion-icon-closed"
                className="home-icon28"
              >
                <path d="M213.333 554.667h256v256c0 23.552 19.115 42.667 42.667 42.667s42.667-19.115 42.667-42.667v-256h256c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-256v-256c0-23.552-19.115-42.667-42.667-42.667s-42.667 19.115-42.667 42.667v256h-256c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
              </svg>
              <svg
                viewBox="0 0 1024 1024"
                data-role="accordion-icon-open"
                className="home-icon30"
              >
                <path d="M213.333 554.667h597.333c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-597.333c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
              </svg>
            </div>
          </div>
          <div
            data-role="accordion-container"
            className="home-element4 accordion"
          >
            <div className="home-content29">
              <span className="home-header39">
                Смогу ли я подготовиться к экзамену, если времени осталось совсем немного?
              </span>
              <span
                data-role="accordion-content"
                className="home-description18"
              >
                  Мы умеем работать в условиях ограниченного времени. Наша программа адаптируется к любым срокам, и вы будете готовиться по четкому плану, чтобы охватить все важные темы. Мы сосредоточимся на ваших слабых местах и поможем максимально эффективно использовать оставшееся время.
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
            </div>
            <div className="home-icon-container4">
              <svg
                viewBox="0 0 1024 1024"
                data-role="accordion-icon-closed"
                className="home-icon32"
              >
                <path d="M213.333 554.667h256v256c0 23.552 19.115 42.667 42.667 42.667s42.667-19.115 42.667-42.667v-256h256c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-256v-256c0-23.552-19.115-42.667-42.667-42.667s-42.667 19.115-42.667 42.667v256h-256c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
              </svg>
              <svg
                viewBox="0 0 1024 1024"
                data-role="accordion-icon-open"
                className="home-icon34"
              >
                <path d="M213.333 554.667h597.333c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-597.333c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
              </svg>
            </div>
          </div>
          <div
            data-role="accordion-container"
            className="home-element5 accordion"
          >
            <div className="home-content30">
              <span className="home-header40">
                Насколько эффективны ваши занятия по сравнению с самостоятельной подготовкой?
              </span>
              <span
                data-role="accordion-content"
                className="home-description19"
              >
                  Самостоятельная подготовка часто занимает больше времени и может привести к упущению важных тем. С нами вы получите структуру, поддержку и проверенные методики, которые позволят значительно ускорить процесс обучения. Кроме того, мы будем сопровождать вас на каждом этапе, помогая решать сложные задачи и готовиться к экзамену уверенно.
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
            </div>
            <div className="home-icon-container5">
              <svg
                viewBox="0 0 1024 1024"
                data-role="accordion-icon-closed"
                className="home-icon36"
              >
                <path d="M213.333 554.667h256v256c0 23.552 19.115 42.667 42.667 42.667s42.667-19.115 42.667-42.667v-256h256c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-256v-256c0-23.552-19.115-42.667-42.667-42.667s-42.667 19.115-42.667 42.667v256h-256c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
              </svg>
              <svg
                viewBox="0 0 1024 1024"
                data-role="accordion-icon-open"
                className="home-icon38"
              >
                <path d="M213.333 554.667h597.333c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-597.333c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
              </svg>
            </div>
          </div>
        </div>
      </section>
      <section className="home-get-yours">
        <div className="home-row3">
          <div className="home-column1">
            <div className="home-card21">
              <img
                alt="image"
                src="/Characters/character-9.svg"
                className="home-image40"
              />
            </div>
          </div>
          <div className="home-column2">
            <div className="home-card22">
              <img
                alt="image"
                src="/Characters/character-10.svg"
                className="home-image41"
              />
            </div>
          </div>
        </div>
        <div className="home-column3">
          <div className="home-card23">
            <div className="home-content31">
              <h2 className="home-header41">Начни готовиться сейчас и сдай ЕГЭ на высокий балл!</h2>
              <p className="home-description20">
                Начните обучение с нами — и достигните своих целей на ЕГЭ с уверенностью!
              </p>
            </div>
            
            <a href="#to_form" className="home-button8 button">Записаться на урок</a>
          </div>
        </div>
      </section>
      <br></br>
      <br></br>
      <br></br>
      <br id="to_form"></br>
      
      <SignupForm />
      <footer className="home-footer">
        <div className="home-main6">
          <div className="home-branding">
            <div className="home-heading20">
              <h2 className="home-logo3">Repita</h2>
              <p className="home-caption27">
                Мы предлагаем уникальные методы обучения, которые помогают нашим ученикам эффективно подготовиться к экзаменам.
              </p>
            </div>
            
          </div>
          <div className="home-links2">
            <div className="home-list2">
              <h3 className="home-heading21">Навигация</h3>
              <div className="home-items1">
                {/* <button className="home-link12 button-clean button">
                  О нас
                </button>
                <button className="home-link13 button-clean button">
                  Collection
                </button>
                <button className="home-link14 button-clean button">
                  План подготовки
                </button>
                <button className="home-link15 button-clean button">
                  Features
                </button> */}
                <a href="#home-container4" className="home-link12 button-clean button">
                  О нас
                </a>
                <a href="#otzivi1" className="home-link12 button-clean button">
                  Отзывы
                </a>
                <a href="#metodika" className="home-link12 button-clean button">
                  Методика
                </a>
                <a href="#programm_cource" className="home-link12 button-clean button">
                  Программа
                </a>
                <a href="#FAQ_voprosi" className="home-link12 button-clean button">
                  FAQ
                </a>
              </div>
            </div>
            
          </div>
          
        </div>
        <span className="home-copyright">
          © 2024 Школа программирования Repita. Все права защищены.
        </span>
      </footer>
      <div>
        <div className="home-container6">
          <Script
            html={`<script>
 /*
  Accordion - Code Embed
  */
  const accordionContainers = document.querySelectorAll('[data-role="accordion-container"]'); // All accordion containers
  const accordionContents = document.querySelectorAll('[data-role="accordion-content"]'); // All accordion content
  const accordionIconsClosed = document.querySelectorAll('[data-role="accordion-icon-closed"]'); // All accordion closed icons
  const accordionIconsOpen = document.querySelectorAll('[data-role="accordion-icon-open"]'); // All accordion open icons

  accordionContents.forEach((accordionContent) => {
      accordionContent.style.display = "none"; //Hides all accordion contents
  });

  accordionIconsClosed.forEach((icon) => {
    icon.style.display = "flex"
  })

  accordionIconsOpen.forEach((icon) => {
    icon.style.display = "none"
  })

  accordionContainers.forEach((accordionContainer, index) => {
      accordionContainer.addEventListener("click", () => {
          if (accordionContents[index].style.display === "flex") {
              // If the accordion is already open, close it
              accordionContents[index].style.display = "none";
              accordionIconsClosed[index].style.display = "flex";
              accordionIconsOpen[index].style.display = "none"
          } else {
              // If the accordion is closed, open it
              accordionContents.forEach((accordionContent) => {
                  accordionContent.style.display = "none"; //Hides all accordion contents
              });

              accordionIconsClosed.forEach((accordionIcon) => {
                  accordionIcon.style.display = "flex"; // Resets all icon transforms to 0deg (default)
              });

              accordionIconsOpen.forEach((accordionIcon) => {
                accordionIcon.style.display = "none";
              })
              
              accordionContents[index].style.display = "flex"; // Shows accordion content
              accordionIconsClosed[index].style.display = "none"; // Rotates accordion icon 180deg
              accordionIconsOpen[index].style.display = "flex";
          }
      });
  });
</script>
`}
          ></Script>
        </div>
      </div>
    </div>
  )
}

export default Home
