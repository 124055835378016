import React, { useState } from 'react';
import './SignupForm.css'; // Подключаем стили для формы

function SignupForm() {
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [writeToMe, setWriteToMe] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const data = { name, phone, writeToMe };

    try {
      const response = await fetch('https://repitaschool.ru/api/send-message', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      if (response.ok) {
        setSubmitted(true);
      } else {
        alert('Ошибка при отправке данных');
      }
    } catch (error) {
      console.error('Ошибка при отправке:', error);
    }
  };

  if (submitted) {
    return <h2>Спасибо, с вами скоро свяжется наш менеджер!</h2>;
  }

  return (
    <section className="home-join-us">
      <div className="home-content25">
        <div className="home-main5">
          <div className="home-heading19">
            <h2 className="home-header34">Начни свою подготовку сегодня</h2>
            <p className="home-caption26">
              Наша программа подготовки уже помогла многим ученикам достичь среднего балла 85+ на ЕГЭ по информатике.
            </p>
          </div>
          <form id="signupForm" className="home-form" onSubmit={handleSubmit}>
            <input
              type="text"
              id="name"
              name="name"
              placeholder="Ваше имя"
              required
              className="home-input"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <input
              type="tel"
              id="phone"
              name="phone"
              placeholder="Ваш номер телефона"
              required
              className="home-input"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
            
            {/* Стилизованный чекбокс */}
            <label className="custom-checkbox">
              <input
                type="checkbox"
                checked={writeToMe}
                onChange={(e) => setWriteToMe(e.target.checked)}
              />
              <span className="checkbox-mark"></span>
              Напишите мне
              
            </label>
            <br></br>
            <button type="submit" className="home-view6 button">
              Записаться на урок
            </button>
          </form>
        </div>
        <img alt="image" src="/group%202273.svg" className="home-image39" />
      </div>
    </section>
  );
}

export default SignupForm;
